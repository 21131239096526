import BaseModel from "./base.class";
import { RoleDto } from "./role.class";

export class AuthResponseSignIn extends BaseModel<AuthResponseSignIn> {
  role?: string;
  token?: string;
  expiredTime?: string;
}

export type UserInfoReq = {
  id?: number;
  name?: string;
  email?: string;
  address?: string;
  zaloContact?: string;
  socialLink?: string;
  cccd?: string;
  newPassword?: string;
  confirmNewPassword?: string;
  isActive?: boolean;
  timeInterview?: string;
};

export class UserDto extends BaseModel<UserDto> {
  id?: number;
  name?: string;
  address?: string;
  email?: string;
  zaloContact?: string;
  socialLink?: string;
  isActive?: boolean;
  cccd?: string;
  role?: RoleDto;
}

export type UserListRes = {
  list: UserDto[];
  total: number;
  page: number;
  pageSize: number;
};
