import { isEmpty } from "lodash";
import { QuestionData, QuestionDto } from "models/classes/question.class";
import { fetchQuestionClientApi, fetchQuestionCreateApi, fetchQuestionDetailApi, fetchQuestionListApi, fetchQuestionUpdateApi } from "./questionFetch";
import { FetchQuestionParams } from "./questionParam";

export const actionQuestionListApi = async ({
  ...params
}: FetchQuestionParams) => {
  try {
    const { data } = await fetchQuestionListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: QuestionDto) =>
          new QuestionDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionQuestionClientApi = async ({
  ...params
}: FetchQuestionParams) => {
  try {
    const { data } = await fetchQuestionClientApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: QuestionDto) =>
          new QuestionDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionQuestionCreateApi = async (body: QuestionData) => {
  try {
    const { data } = await fetchQuestionCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionQuestionDetailApi = async ({
  ...params
}: FetchQuestionParams) => {
  try {
    const { data } = await fetchQuestionDetailApi(params);
    if (!isEmpty(data)) {
      return new QuestionDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionQuestionUpdateApi = async (
  id: number,
  body: QuestionData
) => {
  try {
    const { data } = await fetchQuestionUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};
