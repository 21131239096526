import { Suspense, lazy, useState } from "react";
import styled from "styled-components";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// COMPONENTS
import { Stack } from "@mui/material";
import { useCourseCategoryListByApi } from "apis/courseCategoryApis/useCourseCategoryApis";
const BannerLayout = lazy(() => import("layouts/BannerLayout"));
const CourseStoreList = lazy(() => import("./sections/CourseStoreList"));
const CourseStoreSearch = lazy(() => import("./sections/CourseStoreSearch"));

const CourseStorePage = () => {
  // states
  const [searchName, setSearchName] = useState('');

  // hooks
  const { courseCategoryList } = useCourseCategoryListByApi();

  return (
    <WrapperStyled
      padding={{ xs: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '3rem' }}
    >
      <Suspense>
        <BannerLayout
          height={{ xs: '10rem', md: '12rem' }}
          title="Course"
          subTitle="Choose the study topic you want!"
          imageFile={CharacterBanner}
          widthImage={{ xs: '150px', md: '200px' }}
          heightImage={{ xs: '150px' }}
        />
        <CourseStoreSearch
          searchName={searchName}
          setSearchName={setSearchName}
        />
        <CourseStoreList searchName={searchName} courseCategoryList={courseCategoryList} />
      </Suspense>
    </WrapperStyled>
  )
};

const WrapperStyled = styled(Stack)`
`;

export default CourseStorePage;
