import { createTheme, Theme } from '@mui/material/styles';
import { ThemeData } from './mode';

// COLORS
export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type SizeMap<V = string> = {
  [size in Size]?: V;
};

type LayoutDimension = {
  headerHeight: string;
};

interface NeutralColor {
  primary?: string;
  secondary?: string;
  dark?: string;
  light?: string;
}

interface NeutralThemeColor {
  dark?: string;
  medium?: string;
  main?: string;
  light?: string;
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    dark: string;
    light: string;
    accent: string;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    button: any;
    typeCss: any;
    func: any;
    dimension: LayoutDimension;
  }

  interface PaletteColor {
    mainHover: string;
    mainDisabled?: string;
  }

  interface SimplePaletteColorOptions {
    mainHover?: string;
    mainDisabled?: string;
  }

  interface Palette {
    neutral?: NeutralColor;
    neutralLight?: NeutralThemeColor;
    neutralDark?: NeutralThemeColor;
    accent: Palette['primary'];
    default: Palette['primary']; // default button
    transparent?: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: NeutralColor;
    neutralLight?: NeutralThemeColor;
    neutralDark?: NeutralThemeColor;
    accent: PaletteOptions['primary'];
    default: PaletteOptions['primary'];
    transparent?: PaletteOptions['primary'];
  }
}

// Color Icon Main
const customTheme = (themeData: ThemeData): Theme => {
  return createTheme({
    palette: {
      mode: themeData.mode,
      text: {
        primary: themeData.primaryDark,
        light: themeData.textLight,
        dark: themeData.textDark,
      },
      default: {
        main: themeData.defaultMain,
        light: themeData.defaultLight,
        dark: themeData.defaultDark,
      },
      primary: {
        main: themeData.primaryMain,
        light: themeData.primaryLight,
        dark: themeData.primaryDark,
      },
      accent: {
        main: themeData.accentMain,
        light: themeData.accentLight,
        dark: themeData.accentDark,
        mainHover: themeData.accentMainHover,
        mainDisabled: themeData.accentMainDisabled,
        contrastText: themeData.accentContrastText,
      },
      error: {
        main: themeData.errorMain,
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 1024,
        md: 1366,
        lg: 1440,
        xl: 1600,
      },
    },
  });
};

export default customTheme;
