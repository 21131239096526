import { CourseDescriptionByCourseDetailDto, CourseDescriptionListRes } from "models/classes/courseDescription.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

type State = {
  courseDescriptionListRes: CourseDescriptionListRes | null;
  courseDescription: CourseDescriptionByCourseDetailDto | null;
};

type Actions = {
  updateCourseDescriptionListRes: (data: CourseDescriptionListRes) => void;
  updateCourseDescription: (data: CourseDescriptionByCourseDetailDto) => void;
};

const initState: State = {
  courseDescriptionListRes: null,
  courseDescription: null,
};

const defaultActions: Actions = {
  updateCourseDescriptionListRes: () => { },
  updateCourseDescription: () => { }
};

const CourseDescriptionContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const CourseDescriptionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [courseDescriptionListRes, setCourseDescriptionListRes] = useState<CourseDescriptionListRes | null>(null);
  const [courseDescription, setCourseDescription] = useState<CourseDescriptionByCourseDetailDto | null>(null);

  const updateCourseDescriptionListRes = (data: CourseDescriptionListRes) => {
    setCourseDescriptionListRes(data);
  };

  const updateCourseDescription = (data: CourseDescriptionByCourseDetailDto) => {
    setCourseDescription(data);
  };

  return (
    <CourseDescriptionContext.Provider
      value={{
        courseDescriptionListRes,
        updateCourseDescriptionListRes,

        courseDescription,
        updateCourseDescription,
      }}
    >
      {children}
    </CourseDescriptionContext.Provider>
  );
};

export const useCourseDescriptionContext = () => useContext(CourseDescriptionContext);
