import { isEmpty } from "lodash";
import {
  CourseLessonData,
  CourseLessonDto,
  CourseLessonInfoDto,
} from "models/classes/courseLesson.class";
import {
  fetchCourseLessonApi,
  fetchCourseLessonCreateApi,
  fetchCourseLessonDeleteApi,
  fetchCourseLessonListApi,
  fetchCourseLessonUpdateApi,
  fetchLessonDetailApi,
  fetchLessonDetailByAdminApi,
} from "./courseLessonFetch";
import { FetchCourseLessonParams } from "./courseLessonParam";

export const actionCourseLessonsApi = async ({
  ...params
}: FetchCourseLessonParams) => {
  try {
    const { data } = await fetchCourseLessonApi(params);
    if (!isEmpty(data)) {
      return (data || []).map((item: CourseLessonInfoDto) =>
        new CourseLessonInfoDto().fromPayload(item)
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionLessonDetailApi = async ({
  ...params
}: FetchCourseLessonParams) => {
  try {
    const { data } = await fetchLessonDetailApi(params);
    if (!isEmpty(data)) {
      return new CourseLessonInfoDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionLessonDetailByAdminApi = async ({
  ...params
}: FetchCourseLessonParams) => {
  try {
    const { data } = await fetchLessonDetailByAdminApi(params);
    if (!isEmpty(data)) {
      return new CourseLessonDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseLessonListApi = async ({
  ...params
}: FetchCourseLessonParams) => {
  try {
    const { data } = await fetchCourseLessonListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: CourseLessonDto) =>
          new CourseLessonDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseLessonCreateApi = async (body: CourseLessonData) => {
  try {
    const { data } = await fetchCourseLessonCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseLessonUpdateApi = async (
  id: number,
  body: CourseLessonData
) => {
  try {
    const { data } = await fetchCourseLessonUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseLessonDeleteApi = async ({
  ...params
}: FetchCourseLessonParams) => {
  try {
    const { data } = await fetchCourseLessonDeleteApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};
