import { PageRouteName } from "globals/enums/routes.enum";
import useAbortRequest from "hooks/useAbortRequest";
import useRoute from "hooks/useRoute";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import {
  actionCourseLessonListApi,
  actionCourseLessonsApi,
  actionLessonDetailApi,
  actionLessonDetailByAdminApi,
} from "./courseLessonAction";
import { useCourseLessonContext } from "./courseLessonContext";
import { FetchCourseLessonParams } from "./courseLessonParam";

export const useCourseLessonsByApi = ({
  coursePersonalId,
}: FetchCourseLessonParams) => {
  const { redirect } = useRoute();
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { lessons, updateLessons } = useCourseLessonContext();

  const fetchCourseLessonsByApi = async () => {
    await abortRequest();
    const data = await actionCourseLessonsApi({
      coursePersonalId,
      newAbortSignal,
    });
    if (isEmpty(data)) {
      redirect(PageRouteName.ContactPage, { coursePersonalId });
    }
    updateLessons(data);
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(lessons) || coursePersonalId) {
      fetchCourseLessonsByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [coursePersonalId]);

  return {
    lessons,
    fetchCourseLessonsByApi,
  };
};

export const useLessonDetailByApi = ({
  coursePersonalId,
  courseLessonId,
}: FetchCourseLessonParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { lesson, updateLesson } = useCourseLessonContext();

  const fetchLessonDetailByApi = async () => {
    await abortRequest();
    const data = await actionLessonDetailApi({
      newAbortSignal,
      courseLessonId,
      coursePersonalId,
    });
    updateLesson(data!);
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(lesson) || courseLessonId || coursePersonalId) {
      fetchLessonDetailByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [courseLessonId, coursePersonalId]);

  return {
    lesson,
    fetchLessonDetailByApi,
  };
};

export const useLessonDetailAdminByApi = ({
  coursePersonalId,
  courseLessonId,
}: FetchCourseLessonParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { lessonDetailByAdmin, updateLessonDetailByAdmin } =
    useCourseLessonContext();

  const fetchLessonDetailAdminByApi = async () => {
    await abortRequest();
    const data = await actionLessonDetailByAdminApi({
      newAbortSignal,
      courseLessonId,
      coursePersonalId,
    });
    updateLessonDetailByAdmin(data!);
  };

  // Unmout Apis
  useEffect(() => {
    if (coursePersonalId && courseLessonId) {
      fetchLessonDetailAdminByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [courseLessonId]);

  return {
    lessonDetailByAdmin,
    fetchLessonDetailAdminByApi,
  };
};

export const useCourseLessonListByApi = ({
  coursePersonalId,
  searchName,
  page,
}: FetchCourseLessonParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { lessonListRes, updateLessonListRes } = useCourseLessonContext();

  const fetchCourseLessonListByApi = async () => {
    await abortRequest();
    const data = await actionCourseLessonListApi({
      coursePersonalId,
      searchName,
      page,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateLessonListRes(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(lessonListRes) || coursePersonalId || searchName || page) {
      fetchCourseLessonListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [coursePersonalId, searchName, page]);

  return {
    lessonListRes,
    fetchCourseLessonListByApi,
  };
};
