import { isEmpty } from "lodash";
import {
  fetchCourseCommentCreateApi,
  fetchCourseCommentListApi,
} from "./courseCommentFetch";
import {
  CourseCommentByCoursePersonalIdDto,
  CourseCommentCreateData,
} from "models/classes/courseComment.class";

export const actionCourseCommentListApi = async (coursePersonalId: number) => {
  try {
    const { data } = await fetchCourseCommentListApi({ coursePersonalId });
    if (!isEmpty(data)) {
      return (data || []).map((item: CourseCommentByCoursePersonalIdDto) =>
        new CourseCommentByCoursePersonalIdDto().fromPayload(item)
      );
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseCommentCreateApi = async (
  body: CourseCommentCreateData
) => {
  try {
    const { data } = await fetchCourseCommentCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};
