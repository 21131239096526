import BaseModel from "./base.class";

export class CourseCommentByCoursePersonalIdDto extends BaseModel<CourseCommentByCoursePersonalIdDto> {
  id?: number;
  name?: string;
  nameUser?: string;
  created_at?: string;
}

export type CourseCommentCreateData = {
  name: string;
  coursePersonalId: number;
};
