import { useState } from 'react';

export type IsLoading = boolean;
// eslint-disable-next-line
export type LoadingCallback<T extends (...args: any[]) => Promise<any>> = (
  ...args: Parameters<T>
) => ReturnType<T>;
export type ResetFunc = () => void;
// eslint-disable-next-line
export type AnyError = any;

// eslint-disable-next-line
export const useLoadingCallback = <T extends (...args: any[]) => Promise<any>>(
  callback: T
) => {
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState<any | undefined>();
  const [currentArgs, setCurrentArgs] = useState<any>();

  const handleCallback = async (...args: Parameters<T>) => {
    if (isLoading && JSON.stringify(args) === JSON.stringify(currentArgs)) {
      return;
    }
    setCurrentArgs(args);
    setError(undefined);
    setIsLoading(true);

    try {
      const value = await callback(...args);
      setTimeout(() => setIsLoading(false), 1000);
      return value;
    } catch (e) {
      setError(e);
      setTimeout(() => setIsLoading(false), 1000);
      throw e;
    }
  };

  const reset = () => {
    setIsLoading(false);
    setError(undefined);
  };

  return [handleCallback as LoadingCallback<T>, isLoading, error, reset];
};

export default useLoadingCallback;
