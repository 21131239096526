import { Alert, Snackbar, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "store/snackbarContext";

const SnackbarItem = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { open, type, message, handleClose } = useSnackbarContext();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={type}
        variant="filled"
        sx={{ width: 'auto', fontFamily: '"Montserrat", sans-serif' }}
        style={{ color: theme.palette.text.light }}
      >
        {t(message)}
      </Alert>
    </Snackbar>
  )
};

export default SnackbarItem;
