import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchInterviewCategoryParams } from "./interviewCategoryParam";
import { InterviewCategoryData } from "models/classes/interviewCategory.class";

export const fetchInterviewCategoryListApi = async ({
  searchName,
  page,
  pageSize,
  newAbortSignal,
  ...params
}: FetchInterviewCategoryParams) =>
  publicRequest.get(
    `/interview/list/admin`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchInterviewClientListApi = async ({
  searchName,
  page,
  pageSize,
  newAbortSignal,
  ...params
}: FetchInterviewCategoryParams) =>
  publicRequest.get(
    `/interview/list/client`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchInterviewCreateApi = async (body: InterviewCategoryData) =>
  publicRequest.post(`/interview/create`, body) as any;

export const fetchInterviewDetailApi = async ({
  interviewId,
  newAbortSignal,
  ...params
}: FetchInterviewCategoryParams) =>
  publicRequest.get(
    `/interview/detail/${interviewId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchInterviewUpdateApi = async (
  id: number,
  body: InterviewCategoryData
) => publicRequest.put(`/interview/update/${id}`, body) as any;
