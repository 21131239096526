import { Obj } from "globals/constants/types";
import { isEmpty } from "lodash";
import {
  CourseCategoryAllDto,
  CourseCategoryData,
  CourseCategoryListDto,
} from "models/classes/courseCategory.class";
import {
  fetchCourseCategoryAllApi,
  fetchCourseCategoryCreateApi,
  fetchCourseCategoryDetailApi,
  fetchCourseCategoryListApi,
  fetchCourseCategoryUpdateApi,
} from "./courseCategoryFetch";
import { FetchCourseCategoryParams } from "./courseCategoryParam";

export const actionCourseCategoryListApi = async ({
  ...params
}: FetchCourseCategoryParams) => {
  try {
    const { data } = await fetchCourseCategoryListApi(params);
    if (!isEmpty(data)) {
      return (data || []).map((item: CourseCategoryListDto) =>
        new CourseCategoryListDto().fromPayload(item as Obj)
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseCategoryAllApi = async ({
  ...params
}: FetchCourseCategoryParams) => {
  try {
    const { data } = await fetchCourseCategoryAllApi(params);
    if (!isEmpty(data)) {
      return (data || []).map((item: CourseCategoryAllDto) =>
        new CourseCategoryAllDto().fromPayload(item as Obj)
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseCategoryDetailApi = async ({
  ...params
}: FetchCourseCategoryParams) => {
  try {
    const { data } = await fetchCourseCategoryDetailApi(params);
    if (!isEmpty(data)) {
      return new CourseCategoryAllDto().fromPayload(data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseCategoryCreateApi = async (
  body: CourseCategoryData
) => {
  try {
    const { data } = await fetchCourseCategoryCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseCategoryUpdateApi = async (
  id: number,
  body: CourseCategoryData
) => {
  try {
    const { data } = await fetchCourseCategoryUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};
