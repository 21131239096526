import Project1 from "assets/project-1.png";
import Project2 from "assets/project-2.png";
import Project3 from "assets/project-3.png";
import Project4 from "assets/project-4.png";

export const PROJECTS = [
  {
    id: 1,
    name: "Đồ án website bán hàng",
    description: "Đồ án sử dụng ReactJS, NodeJS",
    content:
      "Đây là đồ án website bán hàng cơ bản với đầy đủ những tính năng, hỗ trợ cho các bạn sinh viên năm 3, năm 4 đang làm đồ án, đồ án này có thể giúp các bạn đạt được 7 đến 8 điểm vì vẫn còn thiếu nhiều tính năng. Các bạn có thể xem mô tả về đồ án nhé ^^",
    numberOfBuyers: 28,
    image: Project1,
    author: "Ninedev",
    price: "350000",
    linkSrc: "",
    video: "https://www.youtube.com/embed/uJC-UAM1coI?si=mguT1vF8yYfZcN5Z",
    highlight: [
      { id: 1, name: "Có thể sử dụng làm đồ án môn học" },
      { id: 2, name: "Có thể đạt được từ 7 hoặc 8 điểm môn học" },
      { id: 3, name: "Giao diện đơn giản đẹp mắt" },
      { id: 4, name: "Chức năng thêm sản phẩm vào giỏ hàng" },
      { id: 5, name: "Chức năng feedback sản phẩm" },
      { id: 6, name: "Chức năng đăng ký" },
      { id: 7, name: "Chức năng đăng nhập" },
      { id: 8, name: "Chức năng tìm kiếm" },
      { id: 9, name: "Chức năng danh mục sản phẩm" },
      { id: 10, name: "Chức năng chi tiết sản phẩm" },
      { id: 11, name: "Chức năng chi tiết giỏ hàng" },
      { id: 12, name: "Chức năng chi tiết đặt hàng" },
      { id: 13, name: "Chức năng đánh giá sản phẩm" },
      { id: 14, name: "Chức năng trò chuyện với admin" },
      { id: 15, name: "Chức năng quản lý sản phẩm" },
      { id: 16, name: "Chức năng quản lý loại sản phẩm" },
      { id: 17, name: "Danh sách đơn hàng" },
      { id: 18, name: "Thanh toán online bằng PayPal" },
      { id: 19, name: "Báo cáo mẫu tham khảo" },
      { id: 20, name: "Có file word hướng dẫn cài đặt đồ án" },
    ],
  },
  {
    id: 2,
    name: "Dự án website dạy học online",
    description: "Dự án sử dụng Laravel",
    content:
      "Đây là dự án website về dạy học online cho mình xây dựng nhằm phục vụ cho việc giảng dạy online, các bạn có thể mua về để sử dụng cho việc kinh doanh hoặc làm đồ án học tập nhé ^^",
    numberOfBuyers: 5,
    image: Project2,
    author: "Ninedev",
    price: "2000000",
    linkSrc: "",
    video: "https://www.youtube.com/embed/XXylLzfqnpw?si=hXQmV_lEAIQJIlqR",
    highlight: [
      { id: 1, name: "Chức năng danh sách các khoá học" },
      { id: 2, name: "Chức năng danh sách các bài học" },
      { id: 3, name: "Chức năng xem chi tiết bài học" },
      { id: 4, name: "Chức năng xem chi tiết khoá học" },
      { id: 5, name: "Chức năng đánh giá nhận xét khoá học" },
      { id: 6, name: "Chức năng danh sách các dự án" },
      { id: 7, name: "Chức năng chi tiết dự án" },
      { id: 8, name: "Chức năng tìm kiếm" },
      { id: 9, name: "Chức năng đăng ký, đăng nhập, đăng xuất" },
      { id: 10, name: "Chức năng phân quyền Admin và học viên" },
      { id: 11, name: "Chức năng quản lý, tham gia khoá học" },
      { id: 12, name: "Chức năng quản lý khoá học" },
      { id: 13, name: "Thêm, sửa, chi tiết, tìm kiếm, phân trang khoá học" },
      { id: 14, name: "Thêm, sửa, chi tiết, tìm kiếm, phân trang dự án" },
      { id: 15, name: "Chức năng quản lý học viên" },
      { id: 16, name: "Chức năng reset mật khẩu" },
      { id: 17, name: "Chức năng học viên mua dự án" },
      { id: 18, name: "Có file hướng dẫn chạy source code" },
      { id: 19, name: "Hỗ trợ dựng source code nếu chạy source code bị lỗi" },
      { id: 20, name: "Hỗ trợ deploy website ra internet" },
    ],
  },
  {
    id: 3,
    name: "Landing page thực phẩm",
    description: "HTML, CSS, JS",
    content:
      "Đây là website landing page mình muốn chia sẽ đến các bạn, các bạn có thể tham khảo nhé ^^",
    numberOfBuyers: 0,
    image: Project3,
    author: "Ninedev",
    price: "",
    linkSrc:
      "https://drive.google.com/file/d/1EiVfMmYAvKZcML8frDUa4BwNhaSdQM0r/view?usp=sharing",
    video: "",
    highlight: [],
  },
  {
    id: 4,
    name: "Clone website Phúc Long Tea",
    description: "HTML, CSS, JS",
    content:
      "Đây là website landing page mình muốn chia sẽ đến các bạn, các bạn có thể tham khảo nhé ^^",
    numberOfBuyers: 0,
    image: Project4,
    author: "Ninedev",
    price: "",
    linkSrc:
      "https://drive.google.com/file/d/1lSOqWUDrHk7nb1DM56Uf5Vs9Wg512Sfh/view?usp=sharing",
    video: "",
    highlight: [],
  },
];
