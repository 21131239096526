import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useRoute from "hooks/useRoute";

// IMAGES
import BgSignIn from 'assets/login-banner-2.png';

// FORMS
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import * as yup from "yup";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import SelectField from "components/formControls/Select";

// CONSTANTS
import { PageRouteName } from "globals/enums/routes.enum";
import { RoleOptions, Roles } from "globals/enums/auth.enum";
import { SelectOption } from "globals/constants/types";
import { UserInfoReq } from "models/classes/auth.class";
import TextField from "components/formHooks/TextField";
import { actionAuthSignUpApi } from "apis/authApis/authAction";
import { useSnackbarContext } from "store/snackbarContext";

const SignInForm = React.memo(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { redirect } = useRoute();

  // forms
  const sectionSchema = yup.object({
    name: yup.string().required('This value cannot be blank').nullable(),
    email: yup.string().required('This value cannot be blank').email("Invalid email").matches(/^(?!.*@[^,]*,)/).nullable(),
    username: yup.string().required('This value cannot be blank').nullable(),
    password: yup.string().required('This value cannot be blank').nullable(),
  });

  const { control, handleSubmit } = useForm<any>({
    resolver: yupResolver(sectionSchema),
  });

  const { updateSnackbar } = useSnackbarContext();

  // states
  const [role, setRole] = useState<Roles>(Roles.STUDENT);

  console.log(role);

  const handleSignUp = async (values: UserInfoReq & { role: string }) => {
    try {
      values = {
        ...values,
        role
      };
      const { data, message } = await actionAuthSignUpApi(values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Create success', open: true });
        redirect(PageRouteName.SignInPage);
      } else {
        updateSnackbar({ type: 'error', message, open: true });
      }
    } catch (error) {
      console.log('handleSignUp', error);
    }
  };

  return (
    <WrapperStyled
      padding={{ xs: '2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection="row"
      alignItems="center"
      borderRadius={3}
      columnGap={{ xs: '2rem' }}
    >
      <Box display={{ xs: 'none', md: 'block' }} width={{ md: '450px' }} height={{ xs: 'auto' }} borderRadius={3} overflow="hidden">
        <img src={BgSignIn} width="100%" height="100%" alt="Đăng ký Ninedev" />
      </Box>
      <Stack
        flexDirection={{ xs: 'column' }}
        rowGap={{ xs: '1.5rem' }}
        flex={1}
      >
        <Stack
          flexDirection={{ xs: 'row' }}
          columnGap={{ xs: '1rem' }}
          width={{ xs: '100%' }}
        >
          <Button
            color="secondary"
            style={{ fontWeight: 300, padding: '1.5rem 0' }}
            shape="round"
            width="100%"
            onClick={() => redirect(PageRouteName.SignInPage)}
          >
            {t('Log in information')}
          </Button>
          <Button
            color="primary"
            style={{ fontWeight: 300, padding: '1.5rem 0' }}
            shape="round"
            width="100%"
          >
            {t('Register an account')}
          </Button>
        </Stack>
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '.5rem' }}
          width={{ xs: '100%' }}
        >
          <Box fontSize={16} color={theme.palette.text.dark}>
            {t('Fullname')}
          </Box>
          <TextField
            control={control}
            name="name"
            placeholder={t('Enter your fullname here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '.5rem' }}
          width={{ xs: '100%' }}
        >
          <Box fontSize={16} color={theme.palette.text.dark}>
            {t('Email')}
          </Box>
          <TextField
            control={control}
            name="email"
            placeholder={t('Enter your email here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '.5rem' }}
          width={{ xs: '100%' }}
        >
          <Box fontSize={16} color={theme.palette.text.dark}>
            {t('What role are you involved in')}
          </Box>
          <SelectField
            width="100%"
            placeHolder={t('Select role')}
            selectedValue={role}
            options={RoleOptions.map(item => ({ label: item.label, value: item.value })) as SelectOption[]}
            onChange={(selectedValue) => setRole(selectedValue as Roles)}
          />
        </Stack>
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '.5rem' }}
          width={{ xs: '100%' }}
        >
          <Box fontSize={16} color={theme.palette.text.dark}>
            {t('Username')}
          </Box>
          <TextField
            control={control}
            name="username"
            placeholder={t('Enter your username here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '.5rem' }}
          width={{ xs: '100%' }}
        >
          <Box fontSize={16} color={theme.palette.text.dark}>
            {t('Password')}
          </Box>
          <TextField
            control={control}
            name="password"
            typeInput="password"
            placeholder={t('Enter your password here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack flexDirection={{ xs: 'row' }}>
          <Button color="primary" style={{ fontWeight: 300 }} onClick={handleSubmit(handleSignUp)}>{t('Sign Up')}</Button>
        </Stack>
      </Stack>
    </WrapperStyled>
  )
});

const WrapperStyled = styled(Stack)`
`;

export default SignInForm;
