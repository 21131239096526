import { CoursePersonalStatus } from "globals/enums/courses.enum";
import BaseModel from "./base.class";
import { UserCoursePersonalDto } from "./user.class";
import { CourseSectionByCourseDetailDto } from "./courseSection.class";
import { CourseDescriptionByCourseDetailDto } from "./courseDescription.class";

export class CoursePersonalNewsDto extends BaseModel<CoursePersonalNewsDto> {
  id?: number;
  name?: string;
  description?: string;
  image?: string;
  author?: string;
}

export class CoursePersonalVipsDto extends BaseModel<CoursePersonalVipsDto> {
  id?: number;
  name?: string;
  numberOfBuyers?: number;
}

export class CoursePersonalListDto extends BaseModel<CoursePersonalListDto> {
  id?: number;
  name?: string;
  description?: string;
  content?: string;
  price?: string;
  image?: string;
  isPublic?: boolean;
  status?: CoursePersonalStatus;
  courseCategoryId?: number;
  author?: string;
  created_at?: string;
  updated_at?: string;
}

export class CoursePersonalDetailDto extends BaseModel<CoursePersonalDetailDto> {
  id?: number;
  name?: string;
  description?: string;
  content?: string;
  price?: string;
  image?: string;
  isPublic?: boolean;
  status?: string;
  user?: UserCoursePersonalDto;
  numberOfBuyers?: number;
  courseSections?: CourseSectionByCourseDetailDto[];
  courseDescriptions?: CourseDescriptionByCourseDetailDto[];
  courseCategoryId?: number;
  created_at?: string;
  updated_at?: string;
}

export type CoursePersonalListRes = {
  list: CoursePersonalListDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type CoursePersonalData = {
  name?: string;
  description?: string;
  content?: string;
  price?: string;
  image?: string;
  isPublic?: boolean;
  courseCategoryId?: number;
  status?: string;
};
