import { isEmpty } from "lodash";
import { useEffect } from "react";

// APIS
import {
  actionCourseDetailPublicApi,
  actionCoursePersonalClientListApi,
  actionCoursePersonalDetailApi,
  actionCoursePersonalNewsApi,
  actionCoursePersonalTeacherListApi,
  actionCoursePersonalVipsApi,
} from "./coursePersonalAction";

// HOOKS
import { PageRouteName } from "globals/enums/routes.enum";
import useAbortRequest from "hooks/useAbortRequest";
import useRoute from "hooks/useRoute";
import { useCoursePersonalContext } from "./coursePersonalContext";
import { FetchCoursePersonalParams } from "./coursePersonalParam";

// Course Personal News
export const useCoursePersonalNewsByApi = () => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { coursePersonalNews, updateCoursePersonalNews } =
    useCoursePersonalContext();

  const fetchCoursePersonalNewsByApi = async () => {
    await abortRequest();
    const data = await actionCoursePersonalNewsApi({ newAbortSignal });
    updateCoursePersonalNews(data);
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(coursePersonalNews)) {
      fetchCoursePersonalNewsByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, []);

  return {
    coursePersonalNews,
    fetchCoursePersonalNewsByApi,
  };
};

// Course Personal Vips
export const useCoursePersonalVipsByApi = () => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { coursePersonalVips, updateCoursePersonalVips } =
    useCoursePersonalContext();

  const fetchCoursePersonalVipsByApi = async () => {
    await abortRequest();
    const data = await actionCoursePersonalVipsApi({ newAbortSignal });
    updateCoursePersonalVips(data);
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(coursePersonalVips)) {
      fetchCoursePersonalVipsByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, []);

  return {
    coursePersonalVips,
    fetchCoursePersonalVipsByApi,
  };
};

export const useCoursePersonalDetailByApi = (coursePersonalId: number) => {
  const { redirect } = useRoute();
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { coursePersonalDetail, updateCoursePersonalDetail } =
    useCoursePersonalContext();

  const fetchCoursePersonalDetailByApi = async () => {
    await abortRequest();
    const data = await actionCoursePersonalDetailApi({
      newAbortSignal,
      coursePersonalId,
    });
    if (!isEmpty(data)) {
      updateCoursePersonalDetail(data!);
    } else {
      redirect(PageRouteName.DashboardPage);
    }
  };

  // Unmout Apis
  useEffect(() => {
    fetchCoursePersonalDetailByApi();
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, []);

  return {
    coursePersonalDetail,
    fetchCoursePersonalDetailByApi,
  };
};

export const useCoursePersonalClientListByApi = ({
  courseCategoryId,
  searchName,
  page,
}: FetchCoursePersonalParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { coursePersonalClientListRes, updateCoursePersonalClientList } =
    useCoursePersonalContext();

  const fetchCoursePersonalClientListByApi = async () => {
    await abortRequest();
    const data = await actionCoursePersonalClientListApi({
      newAbortSignal,
      courseCategoryId,
      searchName,
      page,
    });
    if (!isEmpty(data)) {
      updateCoursePersonalClientList(data);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (
      !isEmpty(coursePersonalClientListRes) ||
      searchName ||
      page ||
      courseCategoryId
    ) {
      fetchCoursePersonalClientListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page, courseCategoryId]);

  return {
    coursePersonalClientListRes,
    fetchCoursePersonalClientListByApi,
  };
};

export const useCoursePersonalTeacherListByApi = ({
  searchName,
  page,
  pageSize,
}: FetchCoursePersonalParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { coursePersonalTeacherListRes, updateCoursePersonalTeacherList } =
    useCoursePersonalContext();

  const fetchCoursePersonalTeacherListByApi = async () => {
    await abortRequest();
    const data = await actionCoursePersonalTeacherListApi({
      newAbortSignal,
      searchName,
      page,
      pageSize,
    });
    if (!isEmpty(data)) {
      updateCoursePersonalTeacherList(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (
      isEmpty(coursePersonalTeacherListRes) ||
      searchName ||
      page ||
      pageSize
    ) {
      fetchCoursePersonalTeacherListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page, pageSize]);

  return {
    coursePersonalTeacherListRes,
    fetchCoursePersonalTeacherListByApi,
  };
};

export const useCourseDetailPublicByApi = (coursePersonalId: number) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { coursePersonalDetail, updateCoursePersonalDetail } =
    useCoursePersonalContext();

  const fetchCourseDetailPublicByApi = async () => {
    await abortRequest();
    const data = await actionCourseDetailPublicApi({
      newAbortSignal,
      coursePersonalId,
    });
    updateCoursePersonalDetail(data!);
  };

  // Unmout Apis
  useEffect(() => {
    if (coursePersonalId) {
      fetchCourseDetailPublicByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, []);

  return {
    coursePersonalDetail: coursePersonalId ? coursePersonalDetail : null,
    fetchCourseDetailPublicByApi,
  };
};
