import { publicRequest } from "apis/configs/requestApis";
import { FetchCourseCommentParams } from "./courseCommentParam";
import { parseRequestParams } from "utils/dataMappers";
import { CourseCommentCreateData } from "models/classes/courseComment.class";

export const fetchCourseCommentListApi = async ({
  coursePersonalId,
  newAbortSignal,
  ...params
}: FetchCourseCommentParams) =>
  publicRequest.get(
    `/course/comment/client/list/${coursePersonalId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCourseCommentCreateApi = async (
  body: CourseCommentCreateData
) => publicRequest.post(`/course/comment/client/create`, body) as any;
