import { isEmpty } from "lodash";
import { AnswerData, AnswerDto } from "models/classes/answer.class";
import {
  fetchAnswerClientDetailApi,
  fetchAnswerCreateApi,
  fetchAnswerDetailApi,
  fetchAnswerListApi,
  fetchAnswerUpdateApi,
} from "./answerFetch";
import { FetchAnswerParams } from "./answerParam";

export const actionAnswerListApi = async ({ ...params }: FetchAnswerParams) => {
  try {
    const { data } = await fetchAnswerListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: AnswerDto) =>
          new AnswerDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionAnswerCreateApi = async (body: AnswerData) => {
  try {
    const { data } = await fetchAnswerCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionAnswerDetailApi = async ({
  ...params
}: FetchAnswerParams) => {
  try {
    const { data } = await fetchAnswerDetailApi(params);
    if (!isEmpty(data)) {
      return new AnswerDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionAnswerClientDetailApi = async ({
  ...params
}: FetchAnswerParams) => {
  try {
    const { data } = await fetchAnswerClientDetailApi(params);
    if (!isEmpty(data)) {
      return new AnswerDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionAnswerUpdateApi = async (id: number, body: AnswerData) => {
  try {
    const { data } = await fetchAnswerUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};
