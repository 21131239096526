export const isObject = (value: any): value is Record<any, any> =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

export const isArray = (value: any): value is any[] => Array.isArray(value);

export const isJson = (value: any): boolean => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}

export const parseBooleanTrue = (value: string | number | boolean | undefined): (boolean | undefined) => {
  if (value !== undefined) {
    if (typeof value === 'string') return value === 'true' || value === '1';
    if (typeof value === 'number') return value === 1;
    return value;
  }
}

export const parseNumber = (value: string | number | undefined): number | undefined => {
  if (value !== undefined) {
    return +value
  }
};

