import useAbortRequest from "hooks/useAbortRequest";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { FetchQuestionParams } from "./questionParam";
import { useQuestionContext } from "./questionContext";
import { actionQuestionClientApi, actionQuestionDetailApi, actionQuestionListApi } from "./questionAction";

export const useQuestionListByApi = ({
  searchName,
  page,
}: FetchQuestionParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { questionListRes, updateQuestionList } =
    useQuestionContext();

  const fetchQuestionListByApi = async () => {
    await abortRequest();
    const data = await actionQuestionListApi({
      searchName,
      page,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateQuestionList(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(questionListRes) || searchName || page) {
      fetchQuestionListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    questionListRes,
    fetchQuestionListByApi,
  };
};

export const useQuestionClientByApi = ({
  searchName,
  page,
  level,
  interviewCategoryId,
}: FetchQuestionParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { questionClientRes, updateQuestionClient } =
    useQuestionContext();

  const fetchQuestionClientByApi = async () => {
    await abortRequest();
    const data = await actionQuestionClientApi({
      searchName,
      page,
      level,
      interviewCategoryId,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateQuestionClient(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(questionClientRes) || searchName || page || level) {
      fetchQuestionClientByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page, level]);

  return {
    questionClientRes,
    fetchQuestionClientByApi,
  };
};

export const useQuestionDetailByApi = ({
  questionId,
}: FetchQuestionParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { questionDetail, updateQuestionDetail } =
    useQuestionContext();

  const fetchQuestionDetailByApi = async () => {
    await abortRequest();
    const data = await actionQuestionDetailApi({
      questionId,
      newAbortSignal,
    });
    updateQuestionDetail(data!);
  };

  // Unmout Apis
  useEffect(() => {
    if (questionId) {
      fetchQuestionDetailByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [questionId]);

  return {
    questionDetail: questionId ? questionDetail : null,
    fetchQuestionDetailByApi,
  };
};
