import styled from 'styled-components';

// COMPONENTS
import { Box } from '@mui/material';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';

declare module '@mui/material/TextField/TextField' {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

export type TextFieldProps = MuiTextFieldProps;
export type InputProps = {
  maxLength?: number;
  typeInput?: string;
  minInput?: number;
  maxInput?: number;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
}

export type StyledProps = {
  paddingRight?: string;
  background?: string;
  labelControl?: string;
  labelError?: string;
}

const TextField = ({
  variant = 'outlined',
  maxLength = 100,
  typeInput = 'text',
  minInput,
  maxInput,
  endAdornment,
  startAdornment,
  background,
  labelControl,
  labelError,
  error,
  required,
  ...restProps
}: TextFieldProps & InputProps & StyledProps) => {
  const { t } = useTranslation();
  return (
    <WrapperTextField>
      {labelControl && <Box className={required ? 'label-required' : ''} mb={1}>{labelControl}</Box>}
      <StyledTextField
        inputProps={{
          maxLength,
          type: typeInput,
          min: minInput,
          max: maxInput,
        }}
        InputProps={{
          endAdornment,
          startAdornment,
        }}
        variant={variant}
        background={background}
        error={error}
        {...restProps}
      />
      {error && <Box className="label-error" mt={1}>{t(String(labelError))}</Box>}
    </WrapperTextField>
  );
};

TextField.defaultProps = {
  autoComplete: "off",
}

const WrapperTextField = styled(Box)`
  .label-required {
    font-size: 14px;
    color: ${p => p.theme.palette.text.light};
    font-weight: 500;
  }

  .label-required::after {
    content: ' *';
    color: #FF4B55;
  }

  .label-error {
    font-size: 12px;
    color: ${p => p.theme.palette.error.main};
  }
`

const StyledTextField = styled(MuiTextField) <StyledProps>`
  label {
    font-size: 14px;
    color: ${p => p.theme.palette.text.light};
    background: ${p => p.theme.palette.primary.main};
    padding: 0 .2rem;
    &.MuiInputLabel-outlined {
      top: -6px;
    }
    &.MuiInputLabel-shrink {
      top: 0;
      color: ${p => p.theme.palette.text.dark};
    }
  }

  .MuiOutlinedInput-root {
    font-family: "Montserrat", sans-serif;
    padding-right: 14px;
    background: ${p => p.background || ''};
    font-size: 14px;
    
    fieldset {
      top: 0;
      border: none;
      legend {
        display: none;
      }
    }

    &.Mui-focused .MuiSvgIcon-root {
      color: #d4d2db;
    }
  }

  .MuiSvgIcon-root {
    color: ${p => p.theme.palette.primary.contrastText};
  }
  
  .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    color: ${p => p.theme.palette.text.light};
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }

  .Mui-disabled {
    background-color: rgb(220 220 220 / 20%) !important;
  }

  .Mui-error {
    color: ${p => p.theme.palette.error.main} !important;
    .MuiOutlinedInput-notchedOutline {
      border-color: ${p => p.theme.palette.error.main} !important;
    }
  }
`;

export default TextField;
