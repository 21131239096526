import useAbortRequest from "hooks/useAbortRequest";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { FetchAnswerParams } from "./answerParam";
import { useAnswerContext } from "./answerContext";
import { actionAnswerClientDetailApi, actionAnswerDetailApi, actionAnswerListApi } from "./answerAction";

export const useAnswerListByApi = ({ searchName, page }: FetchAnswerParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { answerListRes, updateAnswerList } = useAnswerContext();

  const fetchAnswerListByApi = async () => {
    await abortRequest();
    const data = await actionAnswerListApi({
      searchName,
      page,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateAnswerList(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(answerListRes) || searchName || page) {
      fetchAnswerListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    answerListRes,
    fetchAnswerListByApi,
  };
};

export const useAnswerDetailByApi = ({ answerId }: FetchAnswerParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { answerDetail, updateAnswerDetail } = useAnswerContext();

  const fetchAnswerDetailByApi = async () => {
    await abortRequest();
    const data = await actionAnswerDetailApi({
      answerId,
      newAbortSignal,
    });
    updateAnswerDetail(data!);
  };

  // Unmout Apis
  useEffect(() => {
    if (answerId) {
      fetchAnswerDetailByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [answerId]);

  return {
    answerDetail: answerId ? answerDetail : null,
    fetchAnswerDetailByApi,
  };
};

export const useAnswerClientDetailByApi = ({ questionId }: FetchAnswerParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { answerDetail, updateAnswerDetail } = useAnswerContext();

  const fetchAnswerDetailByApi = async () => {
    await abortRequest();
    const data = await actionAnswerClientDetailApi({
      questionId,
      newAbortSignal,
    });
    updateAnswerDetail(data!);
  };

  // Unmout Apis
  useEffect(() => {
    if (questionId) {
      fetchAnswerDetailByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [questionId]);

  return {
    answerDetail: questionId ? answerDetail : null,
    fetchAnswerDetailByApi,
  };
};
