import { AnswerData } from "models/classes/answer.class";
import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchAnswerParams } from "./answerParam";

export const fetchAnswerListApi = async ({
  searchName,
  page,
  pageSize,
  newAbortSignal,
  ...params
}: FetchAnswerParams) =>
  publicRequest.get(
    `/answer/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchAnswerCreateApi = async (body: AnswerData) =>
  publicRequest.post(`/answer/create`, body) as any;

export const fetchAnswerDetailApi = async ({
  answerId,
  newAbortSignal,
  ...params
}: FetchAnswerParams) =>
  publicRequest.get(
    `/answer/detail/${answerId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchAnswerClientDetailApi = async ({
  questionId,
  newAbortSignal,
  ...params
}: FetchAnswerParams) =>
  publicRequest.get(
    `/answer/client/detail/${questionId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchAnswerUpdateApi = async (id: number, body: AnswerData) =>
  publicRequest.put(`/answer/update/${id}`, body) as any;
