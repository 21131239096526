import { CourseLessonDto, CourseLessonInfoDto, CourseLessonListRes } from "models/classes/courseLesson.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

type State = {
  lessons: CourseLessonInfoDto[];
  lesson: CourseLessonInfoDto | null;
  lessonDetailByAdmin: CourseLessonDto | null;
  lessonListRes: CourseLessonListRes | null;
};

type Actions = {
  updateLessons: (lessons: CourseLessonInfoDto[]) => void;
  updateLesson: (lesson: CourseLessonInfoDto) => void;
  updateLessonDetailByAdmin: (lesson: CourseLessonDto) => void;
  updateLessonListRes: (lessonList: CourseLessonListRes) => void;
};

const initState: State = {
  lessons: [],
  lesson: null,
  lessonDetailByAdmin: null,
  lessonListRes: null,
};

const defaultActions: Actions = {
  updateLessons: () => { },
  updateLesson: () => { },
  updateLessonDetailByAdmin: () => { },
  updateLessonListRes: () => { },
};

const CourseLessonContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const CourseLessonProvider = ({ children }: PropsWithChildren<{}>) => {
  const [lessons, setLessons] = useState<CourseLessonInfoDto[]>([]);
  const [lesson, setLesson] = useState<CourseLessonInfoDto | null>(null);
  const [lessonDetailByAdmin, setLessonDetailByAdmin] = useState<CourseLessonDto | null>(null);
  const [lessonListRes, setLessonListRes] = useState<CourseLessonListRes | null>(null);

  const updateLessons = (lessons: CourseLessonInfoDto[]) => {
    setLessons(lessons);
  }

  const updateLesson = (lesson: CourseLessonInfoDto) => {
    setLesson(lesson);
  }

  const updateLessonDetailByAdmin = (lesson: CourseLessonDto) => {
    setLessonDetailByAdmin(lesson);
  }

  const updateLessonListRes = (lessonListRes: CourseLessonListRes) => {
    setLessonListRes(lessonListRes);
  }

  return (
    <CourseLessonContext.Provider
      value={{
        lessons,
        updateLessons,

        lesson,
        updateLesson,

        lessonListRes,
        updateLessonListRes,

        lessonDetailByAdmin,
        updateLessonDetailByAdmin,
      }}
    >
      {children}
    </CourseLessonContext.Provider>
  );
};

export const useCourseLessonContext = () => useContext(CourseLessonContext);
