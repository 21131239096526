
// COMPONENTS
import TextFieldFormControl, {
  InputProps,
  TextFieldProps as TextFieldFormControlProps,
} from 'components/formControls/TextField';
import { Controller, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type TextFieldProps = TextFieldFormControlProps & {
  name: string;
  control?: UseControllerProps['control'];
  defaultValue?: string | number | boolean;
  messageErr?: string;
  height?: string;
  paddingRight?: string;
  background?: string;
} & InputProps;

const TextField = ({
  control,
  required,
  defaultValue,
  name,
  height,
  ...rest
}: TextFieldProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value = '' }, fieldState: { error } }) => {
        const val = value ? value : '';
        return (
          <TextFieldFormControl
            value={val}
            onChange={onChange}
            error={Boolean(error?.message)}
            labelError={t(error?.message!)}
            {...rest}
          />
        );
      }}
      rules={{ required }}
    />
  );
};

export default TextField;
