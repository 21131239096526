import styled from 'styled-components';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material';

type ButtonColor = 'primary' | 'secondary' | 'transparent' | 'success';
type ButtonShape = 'circle' | 'round';

export type ButtonProps = Omit<MuiButtonProps, 'color'> & {
  className?: string;
  label?: string;
  color?: ButtonColor;
  shape?: ButtonShape;
  bold?: boolean;
  fontSize?: number | string;
  width?: number | string;
  height?: number | string;
  loading?: boolean;
};

const Button = ({
  label,
  className,
  color = 'primary',
  shape,
  loading,
  startIcon,
  disabled,
  children,
  ...restProps
}: ButtonProps) => {
  const theme = useTheme();
  const classNames: string[] = [];
  if (className) classNames.push(className);
  if (shape) classNames.push(`button-shape-${shape}`);

  const ButtonBackground: Record<ButtonColor, string> = {
    'primary': 'linear-gradient(#6C40C9, #804FA0)',
    'secondary': '#fff',
    'transparent': 'transparent',
    'success': '#424769',
  };

  const ButtonHover: Record<ButtonColor, string> = {
    'primary': 'linear-gradient(#804FA0, #6C40C9)',
    'secondary': '#e2e2e2',
    'transparent': 'linear-gradient(#804FA0, #6C40C9)',
    'success': '#333752',
  };

  const ButtonColor: Record<ButtonColor, string> = {
    'primary': '#fff',
    'secondary': theme.palette.accent.main,
    'transparent': '#fff',
    'success': '#fff',
  };

  const ButtonDisabled: Record<ButtonColor, string> = {
    'primary': 'linear-gradient(#9c8cbf, #886e99)',
    'secondary': 'rgba(255, 255, 255, 0.7)',
    'transparent': 'rgba(255, 255, 255, 0.7)',
    'success': 'rgba(66, 71, 105, 0.7)',
  };

  return (
    <StyledButton
      bgcolor={ButtonBackground[color]}
      btnhover={ButtonHover[color]}
      btncolor={ButtonColor[color]}
      btndisabled={ButtonDisabled[color]}
      className={classNames.join(' ')}
      startIcon={loading ? <CircularProgress size=".8em" style={{ color: '#fff' }} /> : startIcon}
      disabled={loading || disabled}
      {...restProps}
    >
      {children || label}
    </StyledButton>
  );
};

Button.defaultProps = {
  fontSize: '13',
  height: '40px',
};

type StyleProps = {
  bgcolor?: string;
  btnhover?: string;
  btncolor?: string;
  btndisabled?: string;
  fontSize?: number | string;
  width?: number | string;
  height?: number | string;
};

const StyledButton = styled(MuiButton) <StyleProps>`
  && {
    padding: 0 2rem;
    font-family: 'Montserrat', sans-serif;
    box-shadow: none;
    text-transform: none;
    font-size: ${(p) => p.fontSize};
    background: ${(p) => p.bgcolor};
    color: ${(p) => p.btncolor};

    height: ${(p) => p.height};
    ${(p) => p.width && `width: ${p.width};`}

    @media only screen and (max-width: 1024px) {
      font-size: 12px;
    }

    &:hover {
      box-shadow: none;
      background: ${(p) => p.btnhover};
    }

    &:focus {
      outline: none;
    }

    &.Mui-disabled {
      color: rgba(255, 255, 255, 0.7);
      background: ${(p) => p.btnhover};
    }

    &.button-shape-circle {
      border-radius: 50%;
    }

    &.button-shape-round {
      border-radius: ${(p) => p.height};
    }

    &.MuiButton-containedTransparent {
      font-weight: 500;

      &:hover {
        box-shadow: none;
      }
    }

    &.MuiButton-sizeSmall {
      font-size: 9px;
      padding: 4px 6px;
      min-width: 50px;
    }
  }
`;

export default Button;
