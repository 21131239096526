
import { CourseSectionByCourseDetailDto, CourseSectionListRes } from "models/classes/courseSection.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

type State = {
  courseSectionListRes: CourseSectionListRes | null;
  section: CourseSectionByCourseDetailDto | null;
  sectionAll: CourseSectionByCourseDetailDto[];
};

type Actions = {
  updateSectionList: (courseSectionListRes: CourseSectionListRes) => void;
  updateSection: (section: CourseSectionByCourseDetailDto) => void;
  updateSectionAll: (sectionAll: CourseSectionByCourseDetailDto[]) => void;
};

const initState: State = {
  courseSectionListRes: null,
  section: null,
  sectionAll: [],
};

const defaultActions: Actions = {
  updateSectionList: () => { },
  updateSection: () => { },
  updateSectionAll: () => { },
};

const CourseSectionContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const CourseSectionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [courseSectionListRes, setCourseSectionListRes] = useState<CourseSectionListRes | null>(null);
  const [section, setSection] = useState<CourseSectionByCourseDetailDto | null>(null);
  const [sectionAll, setSectionAll] = useState<CourseSectionByCourseDetailDto[]>([]);

  const updateSectionList = (courseSectionListRes: CourseSectionListRes) => {
    setCourseSectionListRes(courseSectionListRes);
  }

  const updateSection = (section: CourseSectionByCourseDetailDto) => {
    setSection(section);
  }

  const updateSectionAll = (sectionAll: CourseSectionByCourseDetailDto[]) => {
    setSectionAll(sectionAll);
  }

  return (
    <CourseSectionContext.Provider
      value={{
        courseSectionListRes,
        updateSectionList,

        section,
        updateSection,

        sectionAll,
        updateSectionAll,
      }}
    >
      {children}
    </CourseSectionContext.Provider>
  );
};

export const useCourseSectionContext = () => useContext(CourseSectionContext);
