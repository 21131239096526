import { CourseBuyerListByAdminRes, CourseBuyerListByStudentRes } from "models/classes/courseBuyer.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

type State = {
  courseBuyerListByStudent: CourseBuyerListByStudentRes | null;
  courseBuyerListByAdminRes: CourseBuyerListByAdminRes | null;
};

type Actions = {
  updateCourseBuyerListStudent: (courseBuyer: CourseBuyerListByStudentRes) => void;
  updateCourseBuyerListAdminRes: (courseBuyer: CourseBuyerListByAdminRes) => void;
};

const initState: State = {
  courseBuyerListByStudent: null,
  courseBuyerListByAdminRes: null,
};

const defaultActions: Actions = {
  updateCourseBuyerListStudent: () => { },
  updateCourseBuyerListAdminRes: () => { }
};

const CourseBuyerContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const CourseBuyerProvider = ({ children }: PropsWithChildren<{}>) => {
  const [courseBuyerListByStudent, setCourseBuyerListByStudent] = useState<CourseBuyerListByStudentRes | null>(null);
  const [courseBuyerListByAdminRes, setCourseBuyerListByAdminRes] = useState<CourseBuyerListByAdminRes | null>(null);

  const updateCourseBuyerListStudent = (courseBuyer: CourseBuyerListByStudentRes) => {
    setCourseBuyerListByStudent(courseBuyer);
  };

  const updateCourseBuyerListAdminRes = (courseBuyer: CourseBuyerListByAdminRes) => {
    setCourseBuyerListByAdminRes(courseBuyer);
  }

  return (
    <CourseBuyerContext.Provider
      value={{
        courseBuyerListByStudent,
        updateCourseBuyerListStudent,

        courseBuyerListByAdminRes,
        updateCourseBuyerListAdminRes
      }}
    >
      {children}
    </CourseBuyerContext.Provider>
  );
};

export const useCourseBuyerContext = () => useContext(CourseBuyerContext);
