export type ModeType = 'dark' | 'light';

export type ThemeData = {
  // mode
  mode: ModeType;

  // text
  textPrimary: string;
  textLight: string;
  textDark: string;

  // default
  defaultMain: string;
  defaultLight: string;
  defaultDark: string;

  // primary
  primaryMain: string;
  primaryLight: string;
  primaryDark: string;

  // accent
  accentMain: string;
  accentLight: string;
  accentDark: string;
  accentMainHover: string;
  accentMainDisabled: string;
  accentContrastText: string;

  // error
  errorMain: string;
};

export const colorDark: ThemeData = {
  mode: 'dark',
  textPrimary: '#000',
  textLight: '#D5D7DE', // text white
  textDark: '#9B9DA3',
  defaultMain: '#000',
  defaultLight: '#333647', // bg input field
  defaultDark: '#1F212C', //bg header
  primaryMain: '#000', 
  primaryLight: '#000',
  primaryDark: '#191B27', //bg body
  accentMain: '#5d3594', // color main (bg color)
  accentLight: '#000',
  accentDark: '#141625', // bg card
  accentMainHover: '#512e81',
  accentMainDisabled: '#000',
  accentContrastText: '#000',
  errorMain: '#EE4E4E',
};

export const colorLight: ThemeData = {
  mode: 'light',
  textPrimary: '#323232',
  textLight: '#323232',
  textDark: '#323232',
  defaultMain: '#323232',
  defaultLight: '#323232',
  defaultDark: '#323232',
  primaryMain: '#323232',
  primaryLight: '#323232',
  primaryDark: '#323232',
  accentMain: '#323232',
  accentLight: '#323232',
  accentDark: '#323232',
  accentMainHover: '#323232',
  accentMainDisabled: '#323232',
  accentContrastText: '#323232',
  errorMain: '#323232',
};