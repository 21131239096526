import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// HOOKS
import { actionAuthSignInApi } from "apis/authApis/authAction";
import { useAuthContext } from "apis/authApis/authContext";
import useLoadingCallback from "hooks/useLoadingCallback";
import useRoute from "hooks/useRoute";

// IMAGES
import BgSignIn from 'assets/login-banner.svg';

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import TextField from "components/formControls/TextField";

// CONSTANTS
import { StorageKey } from "globals/constants/storage";
import { Roles } from "globals/enums/auth.enum";
import { PageRouteName } from "globals/enums/routes.enum";
import { setStorage } from "services/storageService";
import { useRouteContext } from "store/routeContext";
import { useSnackbarContext } from "store/snackbarContext";

const SignInForm = React.memo(() => {
  // instances
  const { t } = useTranslation();
  const theme = useTheme();
  const { redirect } = useRoute();

  // states
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // hooks
  const { updateRole, updateToken, updateExpiredTime } = useAuthContext();
  const { prevRoute, updatePrevRoute } = useRouteContext();
  const { updateSnackbar } = useSnackbarContext();

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSignIn();
    }
  };

  const [handleSignIn, isLoadingSignIn] = useLoadingCallback(async () => {
    try {
      const data = await actionAuthSignInApi({ username, password });
      if (!isEmpty(data)) {
        updateToken(data?.token!);
        updateRole(data?.role! as Roles);
        updateExpiredTime(data?.expiredTime!);
        setStorage(StorageKey.AccessToken, data?.token);
        setStorage(StorageKey.Role, data?.role);
        setStorage(StorageKey.ExpiredTime, data?.expiredTime);
        if (prevRoute) {
          redirect(prevRoute);
          updatePrevRoute(prevRoute);
        } else redirect(PageRouteName.DashboardPage);
      } else {
        updateSnackbar({ open: true, type: 'error', message: 'Wrong login information' });
      }
    } catch (error) {
      console.log('handleSignIn', error);
    }
  });

  return (
    <WrapperStyled
      padding={{ xs: '2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection="row"
      alignItems="center"
      borderRadius={3}
      columnGap={{ xs: '2rem' }}
    >
      <Box display={{ xs: 'none', sm: 'block' }} width={{ xs: '220px', md: '400px' }} height={{ xs: 'auto' }} borderRadius={3} overflow="hidden">
        <img src={BgSignIn} width="100%" height="100%" alt="Đăng nhập Ninedev" />
      </Box>
      <Stack
        flexDirection={{ xs: 'column' }}
        rowGap={{ xs: '1.5rem' }}
        flex={1}
      >
        <Stack
          flexDirection={{ xs: 'row' }}
          columnGap={{ xs: '1rem' }}
          width={{ xs: '100%' }}
        >
          <Button
            color="primary"
            style={{ fontWeight: 300, padding: '1.5rem 0' }}
            shape="round"
            width="100%"
          >
            {t('Log in information')}
          </Button>
          <Button
            color="secondary"
            style={{ fontWeight: 300, padding: '1.5rem 0' }}
            shape="round"
            width="100%"
            onClick={() => redirect(PageRouteName.SignUpPage)}
          >
            {t('Register an account')}
          </Button>
        </Stack>
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '.5rem' }}
          width={{ xs: '100%' }}
        >
          <Box fontSize={16} color={theme.palette.text.dark}>
            {t('Username')}
          </Box>
          <TextField
            required={false}
            placeholder={t('Enter your username here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Stack>
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '.5rem' }}
          width={{ xs: '100%' }}
        >
          <Box fontSize={16} color={theme.palette.text.dark}>
            {t('Password')}
          </Box>
          <TextField
            required={false}
            typeInput="password"
            placeholder={t('Enter your password here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Stack>
        <Stack flexDirection={{ xs: 'row' }}>
          <Button
            color="primary"
            style={{ fontWeight: 300 }}
            loading={isLoadingSignIn}
            onClick={handleSignIn}
          >
            {t('Sign In')}
          </Button>
        </Stack>
      </Stack>
    </WrapperStyled>
  )
});

const WrapperStyled = styled(Stack)`
`;

export default SignInForm;
