
import moment from 'moment';
import { saveLangCode } from 'locales';
import i18n from 'i18next';
import { Lang } from 'globals/constants/i18n';

const formatLocalCode = (language: Lang) => {
  switch (language) {  
    default:
      return language;
  }
}

// For moment, only en is loaded as default
const loadedMomentLanguages = ['en'];

// Change language and set new locale to related features
export const changeLanguage = (language: Lang) => {
  // Set locale for moment
  if (!loadedMomentLanguages.includes(language)) {
    require(`moment/locale/${formatLocalCode(language)}`);
    loadedMomentLanguages.push(language);
  }

  if (i18n.language === language) {
    return;
  }

  moment.locale(language);

  console.log(language);

  // Change locale
  saveLangCode(language);
  i18n.changeLanguage(language);
};
