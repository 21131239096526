import React, { useState } from "react";

// CONSTANTS
import { Lang } from "globals/constants/i18n";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";

// FUNCTIONS
import { changeLanguage } from "services/localeService";
import { getLangCode } from "locales";

const HeaderLanguage = React.memo(() => {
  const [currentLanguage, setCurrentLanguage] = useState<Lang>(getLangCode())
  const theme = useTheme();

  const handleChangeLanguage = (language: Lang) => {
    setCurrentLanguage(language);
    changeLanguage(language);
  };

  return (
    <Stack flexDirection="row" justifyContent="center" alignItems="center" columnGap="1.5rem">
      <Box
        color={currentLanguage === 'vi' ? theme.palette.text.light : theme.palette.text.dark}
        style={{ cursor: 'pointer' }}
        onClick={() => handleChangeLanguage('vi')}
        className={currentLanguage === 'vi' ? 'bg-main' : ''}
        padding={{ xs: '.3rem .8rem' }}
        borderRadius={{ xs: '4px' }}
      >
        VN
      </Box>
      <Box color={theme.palette.text.light}>|</Box>
      <Box
        color={currentLanguage === 'en' ? theme.palette.text.light : theme.palette.text.dark}
        style={{ cursor: 'pointer' }}
        onClick={() => handleChangeLanguage('en')}
        className={currentLanguage === 'en' ? 'bg-main' : ''}
        padding={{ xs: '.3rem .8rem' }}
        borderRadius={{ xs: '4px' }}
      >
        EN
      </Box>
    </Stack>
  );
});

export default HeaderLanguage;
