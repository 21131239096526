import { Suspense, lazy, useMemo } from "react";
import styled from "styled-components";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";

// CONSTANTS
import { PROJECTS } from "globals/enums/projects.enum";
import { PageRouteName } from "globals/enums/routes.enum";
import { ProjectDetailDto, ProjectListRes } from "models/classes/project.class";

// COMPONENTS
import { Stack } from "@mui/material";

// LAZY
const BannerLayout = lazy(() => import("layouts/BannerLayout"));
const ProjectList = lazy(() => import("./sections/ProjectList"));
const ProjectSearch = lazy(() => import("./sections/ProjectSearch"));

const ProjectPage = () => {
  // instances
  const { redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const searchName = searchParams.get('name') ? String(searchParams.get('name')) : '';
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const projectListRes = useMemo<ProjectListRes>(() => {
    let projects = PROJECTS.map(item => new ProjectDetailDto().fromPayload(item))
      .filter(item => item.name?.toUpperCase().indexOf(searchName.toUpperCase()) !== -1);
    const newProjects = projects.slice((page - 1) * 10, page * 10);
    return {
      list: [...newProjects],
      total: projects.length,
      page,
      pageSize: 10,
    };
  }, [searchName, page]);

  return (
    <WrapperStyled
      padding={{ xs: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '2rem' }}
    >
      <Suspense>
        <BannerLayout
          height={{ xs: '10rem', md: '12rem' }}
          title="Project"
          subTitle="Choose your favorite project!"
          imageFile={CharacterBanner}
          widthImage={{ xs: '150px', md: '200px' }}
          heightImage={{ xs: '150px' }}
        />
        <ProjectSearch
          title="Search project"
          searchName={searchName}
          onSubmitSearch={(searchName: string) =>
            redirect(PageRouteName.ProjectPage, { page: 1, name: searchName })
          }
        />
        <ProjectList
          projectListRes={projectListRes}
          page={page}
          setPage={(page) => redirect(PageRouteName.ProjectPage, { page, name: searchName })}
        />
      </Suspense>
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Stack)`
`;

export default ProjectPage;
