import { isEmpty } from "lodash";
import { FetchInterviewCategoryParams } from "./interviewCategoryParam";
import {
  fetchInterviewCategoryListApi,
  fetchInterviewClientListApi,
  fetchInterviewCreateApi,
  fetchInterviewDetailApi,
  fetchInterviewUpdateApi,
} from "./interviewCategoryFetch";
import {
  InterviewCategoryData,
  InterviewCategoryDto,
} from "models/classes/interviewCategory.class";

export const actionInterviewCategoryApi = async ({
  ...params
}: FetchInterviewCategoryParams) => {
  try {
    const { data } = await fetchInterviewCategoryListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: InterviewCategoryDto) =>
          new InterviewCategoryDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionInterviewClientApi = async ({
  ...params
}: FetchInterviewCategoryParams) => {
  try {
    const { data } = await fetchInterviewClientListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: InterviewCategoryDto) =>
          new InterviewCategoryDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionInterviewCreateApi = async (body: InterviewCategoryData) => {
  try {
    const { data } = await fetchInterviewCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionInterviewDetailApi = async ({
  ...params
}: FetchInterviewCategoryParams) => {
  try {
    const { data } = await fetchInterviewDetailApi(params);
    if (!isEmpty(data)) {
      return new InterviewCategoryDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionInterviewUpdateApi = async (
  id: number,
  body: InterviewCategoryData
) => {
  try {
    const { data } = await fetchInterviewUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};
