
import { AnswerDto, AnswerListRes } from "models/classes/answer.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

type State = {
  answerListRes: AnswerListRes | null;
  answerDetail: AnswerDto | null;
};

type Actions = {
  updateAnswerList: (answerListRes: AnswerListRes) => void;
  updateAnswerDetail: (answerDetail: AnswerDto) => void;
};

const initState: State = {
  answerListRes: null,
  answerDetail: null,
};

const defaultActions: Actions = {
  updateAnswerList: () => { },
  updateAnswerDetail: () => { },
};

const AnswerContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const AnswerProvider = ({ children }: PropsWithChildren<{}>) => {
  const [answerListRes, setQuestionListRes] = useState<AnswerListRes | null>(null);
  const [answerDetail, setQuestionDetail] = useState<AnswerDto | null>(null);

  const updateAnswerList = (answerListRes: AnswerListRes) => {
    setQuestionListRes(answerListRes);
  };

  const updateAnswerDetail = (answerDetail: AnswerDto) => {
    setQuestionDetail(answerDetail);
  };

  return (
    <AnswerContext.Provider
      value={{
        answerListRes,
        updateAnswerList,

        answerDetail,
        updateAnswerDetail
      }}
    >
      {children}
    </AnswerContext.Provider>
  );
};

export const useAnswerContext = () => useContext(AnswerContext);
